import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SubscribeEmail from "../components/newsletter/SubscribeEmail";
import CircleCountdown from "../components/countdown/CircleCountdown";

const ComingSoon19 = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />

        <style>
          {`
            .cs-19-countdown .countdown-item{
              background-image: url(${
                process.env.PUBLIC_URL + "/assets/img/icons/circle.svg"
              })
            }
          `}
        </style>
      </Helmet>
      <div
        className="cs-19-page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL +
            "/assets/img/backgrounds/coming-soon-20.jpg"
          })`
        }}
      >

        {/*====================  content ====================*/}
        <div className="cs-19-content">
          <div className="container">
            <div className="row">
              <div className="col-6 ml-auto mr-auto">
                {/* logo */}
                <div className="cs-19-logo text-center space-mb--35">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    <img
                    style={{width: '250px', height: '250px'}}
                      src={
                        process.env.PUBLIC_URL + "/assets/img/logo/logo.svg"
                      }
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 ml-auto mr-auto">
                <h2 className="cs-19-content__title text-center space-mb--20">
                  DIÇKA E RE PO VJEN
                </h2>
                <p className="cs-19-content__text text-center space-mb--50">
                  A don me gjet banesë ma shpejtë? A nashta je ka don me lshu banesën me qera.<br/>Na jem tu e bo ni platformë që ka me t'a lehtësu punën për kto gjana.
                  Jo veç për kto po edhe për me shit e me ble prona. Amo janë edhe plot surpriza tjera.
                </p>
                {/* countdown */}
                <div className="cs-19-countdown">
                  <CircleCountdown
                    timeTillDate="01 05 2025, 6:00 pm"
                    timeFormat="MM DD YYYY, h:mm a"
                  />
                </div>
                <div className="cs-19-subscription-wrapper space-mt--70 text-center">
                  {/* subscribe email */}
                  <SubscribeEmail mailchimpUrl="https://gmail.us10.list-manage.com/subscribe/post?u=21776f7c6b3f510575a872e96&amp;id=d3f4e61800&amp;f_id=009491e5f0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of content  ====================*/}
        {/*====================  footer ====================*/}
        <footer className="cs-19-footer space-pt--25 space-pb--25">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                {/* social icons */}
                {/* <ul className="cs-19-social-icons d-flex align-items-center justify-content-center space-mb--30">
                  <li>
                    <a
                      href="http://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/facebook-04.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/twitter-04.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/linkedin-04.svg"
                        }
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReactSVG
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/icons/youtube-04.svg"
                        }
                      />
                    </a>
                  </li>
                </ul> */}
                {/* copyright */}
                <div className="cs-19-copyright text-center">
                  &copy; {new Date().getFullYear() + " "}
                  <a
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tukapik
                  </a>
                  , të gjitha të drejtat e rezervuara
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====================  End of footer  ====================*/}
      </div>
    </Fragment>
  );
};

export default ComingSoon19;
